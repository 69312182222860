import api from '@/services/api';
import Hub from '@/plugins/event-hub';

export default class UploadAdapter {
  constructor(loader) {
    this.loader = loader;
    this.api = api;
  }

  // Starts the upload process.
  upload() {
    const formData = new FormData();

    return this.loader.file.then((file) => {
      formData.append('file', file, file.name);

      return this.api.fileStorage.upload(formData).then(({ data }) => ({
        default: data.url,
      }));
    });
  }

  abort() {
    if (this.api.abortController) {
      this.api.abortController.abort();
      this.api.reload(1000);
    }

    Hub.$emit('show-message', { message: 'Не удалось загрузить файл', type: 'error' });
  }
}
