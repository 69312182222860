<template>
  <div class="wysiwyg">
    <ckeditor
      :editor="editor"
      v-model="localValue"
      :config="editorConfig"
      @ready="onReady"
    />
  </div>
</template>

<script>
import '@ckeditor/ckeditor5-build-classic/build/translations/ru';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import UploadAdapter from './UploadAdapter';

// eslint-disable-next-line func-names
const customUploadAdapterPlugin = function (editor) {
  // eslint-disable-next-line no-param-reassign
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => (new UploadAdapter(loader));
};

export default {
  name: 'Wysiwyg',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      editor: DecoupledEditor,
      editorConfig: {
        extraPlugins: [
          customUploadAdapterPlugin,
        ],
        language: 'ru',
        toolbar: [
          'heading',
          'fontFamily',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'alignment:left',
          'alignment:center',
          'alignment:right',
          'alignment:justify',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          '|',
          'insertTable',
          'imageUpload',
          'undo',
          'redo',
        ],
        height: 500,
        link: {
          decorators: {
            isExternal: {
              mode: 'manual',
              label: 'Open in a new tab',
              attributes: {
                target: '_blank',
              },
            },
          },
        },
      },
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onReady(editor) {
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement(),
        );
    },
  },
};
</script>
